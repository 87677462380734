.pre-register {
  .main-box-header {
    & {
      color: #03a9f4;
    }
    h2 {
      font-weight: bold;
    }
  }
  .panel-group {
    margin-bottom: 3px;
  }
  .panel-heading {
    & {
      background-color: #394560;
    }
    &.invoiced-na {
      background-color: #394560 !important;
    }
    &.invoiced {
      background-color: #36984A;
    }
    &.not-invoiced {
      background-color: #C2A000;      
    }
  }
  .panel-collapse {
    & {
      border: none;
      background-color: #F6F6F6;
    }
    .panel-box {
      background-color: #E7E7E7;
    }
  }
  .panel-heading {
    & {
      padding: 0;
    }
    &:after,
    &:before {
      content: '';
      display: table;
    }
    .accordion-toggle {
      & {
        padding: 10px 15px;
      }
      &:after {
        margin-right: 10px;
      }
    }
  }
  .accordion-toggle {
    cursor: pointer;
  }
  .panel-body {
    .table-responsive {
      &,
      table,
      thead,
      tr,
      th {
        border: none !important;
      }
      td,
      th {
        text-transform: none;
      }
    }
  }
  .remove-all-inclusive {
    font-size: 0;
    &:after {
      content: '✖';
      color: red;
      display: inline;
      font-size: 1.5rem;
      line-height: 1;
    }
  }
  .invoice-status,
  .all-inclusive,
  .seminar-blocked {
    background-color: rgba(0,0,0,.5);
    border-radius: 3px;
    padding: 3px 5px;
    margin: -3px 15px 0 0;
  }
  .all-inclusive,
  .invoice-status {
    float: right;
  }
  .seminar-blocked {
    float: left;
  }
  .panel-box {
    padding: 20px;
    background-color: #F6F6F6;
  }
  .location-list {
    background-color: #E7E7E7;
    padding: 5px;
    margin: 0;
  }
  .charcount {
    color: #B8B8B8;
    font-size: 10px;
  }
  .remaining {
    @extend .red;
    margin-top: 5px;
  }
  .venue {
    background-color: #FFAD05;
    color: white;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 2px 5px;
    margin: -2px 5px 0 3px;
    font-size: 10px;
  }
  .hotel-container {
    & {
      padding: 0 20px;
    }
    & + .hotel-container {
      margin-top: 20px;
    }
    .hotel-info {
      & {
        display: flex;
      }
      i {
        color: #B8B8B8;
      }
      .hotel-details {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
      }
      .hotel-rates {
        color: #6E6E6E;
      }
      .single-rate {
        &:before {
          font: normal normal normal 14px/1 FontAwesome;
          content: "\f007";
          display: inline;
          margin-right: 5px;
        }
        &:after {
          content: "|";
          display: inline;
          margin: 0 10px;
        }
      }
      .double-rate {
        &:before {
          font: normal normal normal 14px/1 FontAwesome;
          content: "\f007\f007";
          display: inline;
          margin-right: 5px;
          letter-spacing: 1px;
        }
      }
    }
    .hotel-reservations {
      & {
        background-color: #E7E7E7;
      }
      .reservation-type {
        & {
          border-bottom: 1px solid #DADADA;
          margin-bottom: 10px;
          padding-bottom: 5px;
        }
        strong {
          text-transform: capitalize;
        }
      }
    }
  }
  .table .table {
    & {
      background-color: inherit;
    }
    tbody {
      td {
        border-top: 1px solid #D3D3D3;
      }
    }
  }
  .checkbox-cell {
    & {
      display: flex;
      align-items: center;
    }
    .label {
      & {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        color: #212121;
        text-align: left;
        font-weight: normal;
      }
      p {
        & {
          margin: 0;
        }
        & + p {
          margin-top: 5px;
        }
      }
    }
  }
  .flex-group {
    & {
      display: flex;
      margin-bottom: 10px;
    }
    input,
    .row,
    .location-list {
      flex: 1;
      margin-right: 10px;
    }
    .list-inline {
      display: inline;
    }
  }
  .hotel-entry {
    & {
      display: flex;
      margin-bottom: 10px;
    }
    .hotel-info {
      flex: 1;
      margin-right: 10px;
    }
    .btn {
      max-height: 32px;
      align-self: center;
    }
  }
  .form-group {
    margin-bottom: 10px;
  }
}
.border-none {
  border: none !important;
}
.ui-select-choices {
  z-index: 9999999;
}
.form-control {
  &.ng-pristine {
    + .help-block  {
      display: none;
    }
  }
  &.ng-invalid.ng-dirty {
    & {
      border-color: #e84e40;
    }
    + .help-block  {
      color: #e84e40;
    }
  }
}
.panel-title {
  .required {
    color: #e84e40;
  }
}