.profile-box-contact {
	.main-box-body {
		padding: 0;
	}
	.profile-box-header {
		padding: 30px 20px;
		color: #fff;
		@include border-radius($border-radius-base $border-radius-base 0 0);
	}
	.profile-img {
		@include border-radius(18%);
		width: 110px;
		height: 110px;
		float: left;
		margin-right: 15px;
		border: 5px solid #fff;
	}
	h2 {
		padding: 8px 0 3px;
		margin: 0;
		display: inline-block;
		font-weight: 400;
		line-height: 1.1;
	}
	.job-position {
		font-weight: 300;
		font-size: 0.875em;
	}
	.profile-box-footer {
		padding-top: 10px;
		padding-bottom: 15px;
	
		a {
			display: block;
			width: 33%;
			width: 33.33%;
			float: left;
			text-align: center;
			padding: 15px 10px;
			color: $text-color;
		
			&:hover {
				text-decoration: none;
			}
		}
		.value {
			display: block;
			font-size: 1.8em;
			font-weight: 300;
		}
		.label {
			display: block;
			font-size: 1em;
			font-weight: 300;
			color: $text-color;
		}
	}
	.contact-details {
		padding: 4px 0 0;
		margin: 0;
		list-style: none;
		font-size: 0.875em;
		font-weight: 300;
	
		li {
			i {
				width: 12px;
				text-align: center;
				margin-right: 3px;
			}
		}
	}
}