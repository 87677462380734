.xs-graph {

	.graph-label {
		font-size: 0.875em;
		padding-bottom: 5px;
		color: #616161;
	}
	.graph-content {

	}
}
.jqstooltip {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	border-radius: $border-radius-base;
	border: 0 !important;
	background: rgba(0,0,0,0.7) !important;
	opacity: 1;
}

#flot-tooltip {
	position: absolute;
	background: none repeat scroll 0 0 rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(230, 230, 230, 0.8);
    border-radius: 10px;
    color: #666;
    font-family: sans-serif;
    font-size: 12px;
    padding: 6px;
    text-align: center;
}
#flot-tooltip span {
	display: block;
}
#flot-tooltip b {
	font-weight: bold;
    margin: 0.25em 0;
    color: #666;
    font-family: sans-serif;
    font-size: 12px;
    text-align: center;
}
#flot-tooltip i {
	margin: 0.1em 0;
    white-space: nowrap;
	color: #666;
    font-family: sans-serif;
    font-size: 12px;
    text-align: center;
    font-style: normal;
}
.legend .legendColorBox > div {
	margin-right: 7px;
	border: none !important;
}

.graph-stats {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-top: 10px;

	li {
		padding: 0;

		.title {
			color: lighten($text-color, 10%);
			margin-top: 12px;
			font-size: 0.875em;
		}
		.value {
			color: lighten($text-color, 10%);
			font-size: 1.6em;
			font-weight: 300;

			> .fa {
				font-size: 0.6em;
			}
			.tooltip {
				font-weight: 400;
			}
		}
		.progress {
			height: 5px;
		}
	}
}