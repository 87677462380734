/* PRICING */
.pricing-package {
	padding-top: 20px;
	padding-bottom: 20px;

	.pricing-package-inner {
		color: #7d7d7d;
		position: relative;
		@include transition(border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s);
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
		@include border-radius($border-radius-small);
	}
	.pricing-star {
		background: url(../../img/pricing-star.png) no-repeat;
		position: absolute;
		width: 39px;
		height: 40px;
		color: #fff;
		font-size: 0.65em;
		text-align: center;
		line-height: 1.1;
		padding-top: 9px;
		right: -10px;
		top: -20px;
	}
	.package-header {
		background: $primary-color;
		@include border-radius($border-radius-small $border-radius-small 0 0);
	
		h3 {
			line-height: 1;
			text-align: center;
			color: #fff;
			margin: 0;
			padding: 20px 0 0;
			border: 0;
			font-size: 1.2em;
			text-transform: uppercase;
			font-weight: 400;
		}
		.stars {
			color: #fff;
			text-align: center;
			padding-top: 8px;
			display: none;
		}
	}
	.package-content {
		padding: 0;
		margin: 0;
		border: 1px solid $main-bg-color;
		border-top-color: transparent;
		@include border-radius(0 0 $border-radius-small $border-radius-small);
	
		.package-price {
			font-weight: bold;
			text-align: center;
			height: 80px;
			line-height: 75px;
			background: $primary-color;
			color: #fff;
			margin: -1px;
			position: relative;
		
			.package-month {
				font-size: 1em;
				font-weight: normal;
			}
			.price {
				font-size: 4em;
				font-weight: 400;
			}
			.currency {
				font-size: 1.5em;
				font-weight: 600;
			}
		}
	}
	.package-top-features {
		list-style: none;
		margin: 0;
		padding: 0;
	
		li {
			border-bottom: 1px solid $main-bg-color;
			text-align: center;
			font-size: 1.125em;
			line-height: 1.5;
			padding: 8px 0;
		
			&:first-child {
				border-top: 1px solid $main-bg-color;
			}
		}
	}
	.package-features {
		list-style: none;
		margin: 0 18px;
		padding: 15px 0;
		position: relative;
	
		li {
			font-size: 0.875em;
			padding: 6px 0;
			line-height: 1.3;
			color: #7d7d7d;
			padding-left: 25px;
		
			&.has-feature {
				@include transition(color 0.1s ease-in-out 0s);
			}
			&.has-nofeature {
				text-decoration: line-through;
			}
			&.has-feature:before {
				content: "\f00c";
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				font-size: 1.3em;
				text-decoration: inherit;
				color: $green-color;
				margin-top: -2px;
				position: absolute;
				left: 0;
				text-decoration: none;
			}
			&.has-nofeature:before {
				content: "\f00d";
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				font-size: 1.3em;
				text-decoration: inherit;
				color: $red-color;
				margin-top: -2px;
				position: absolute;
				left: 0;
				text-decoration: none;
			}
		}
	}
	.package-footer {
		text-align: center;
		padding: 10px 0 25px;
	
		.btn > span {
			padding-right: 6px;
			@include transition(color 0.1s ease-in-out 0s);
		}
		.btn:hover > span {
			color: #fff;
		}
	}

	&.simple {
		.package-header h3 {
			font-size: 1.3em;
			padding: 0;
			line-height: 42px;
			height: 42px;
		}
		.package-content .package-price {
			font-size: 1.8em;
			height: 60px;
			line-height: 60px;
			background: transparent;
			color: inherit;
		}
		.package-top-features li {
			font-size: 0.875em;
		}
		.package-top-features li:last-child {
			border-bottom: 0;
		}
	}
	&:hover {
		.pricing-package-inner {
			background-color: #fcfcfc;
			color: $text-color;
		}
		.package-features li.has-feature {
			color: $text-color;
		}
	}
}