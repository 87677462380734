/* NESTABLE LIST */
.dd { 
	position: relative; 
	display: block; 
	list-style: none; 
	font-size: 13px; 
	line-height: 20px; 

	@media only screen and (min-width: 700px) { 
		float: left;
		width: 48%; 
	}
}
.dd-list { 
	display: block; 
	position: relative; 
	margin: 0; 
	padding: 0; 
	list-style: none; 
}
.dd-list .dd-list { 
	padding-left: 30px; 
}
.dd-collapsed .dd-list { 
	display: none; 
}
.dd-item,
.dd-empty,
.dd-placeholder { 
	display: block;
	position: relative; 
	margin: 0; 
	padding: 0; 
	min-height: 20px; 
	font-size: 13px; 
	line-height: 20px; 
}
.dd-handle-list {
	position: absolute;
	width: 30px;
	height: 30px;
	background: #E1E1E1;
	top: 0;
	left: 0;
	z-index: 1;
	font-size: 1em;
	color: #000000;
	text-align: center;
	border: 1px solid #E1E1E1;
	padding-top: 4px;
}
.dd-handle { 
	background: none repeat scroll 0 0 #F6F6F8;
	border: 2px solid #e1e1e1;
	@include border-radius(0);
	color: #000000;
	display: block;
	font-size: 0.875em;
	font-weight: 700;
	height: 32px;
	margin: 5px 0;
	padding: 5px 10px;
	text-decoration: none;
	@include box-sizing(border-box); 

	&:hover { 
		color: $primary-color; 
		background: #fff; 
	}
	.nested-links {
		float: right;
	
		.nested-link {
			margin: 0 2px;
		}
		.nested-link:last-child {
			margin-right: 0;
		}
		.nested-link i {
			font-size: 1.25em;
		}
	}
}
.dd-item-list .dd-handle {
	padding-left: 40px;
}
.dd-item {
	> button { 
		display: block; 
		position: relative; 
		cursor: pointer; 
		float: left; 
		width: 25px; 
		height: 20px; 
		margin: 5px 0; 
		padding: 0; 
		text-indent: 100%; 
		white-space: nowrap; 
		overflow: hidden; 
		border: 0; 
		background: transparent; 
		font-size: 12px; 
		line-height: 1; 
		text-align: center; 
		font-weight: bold;
	}
	> button:before { 
		content: '+'; 
		display: block; 
		position: absolute; 
		width: 100%; 
		text-align: center; 
		text-indent: 0; 
	}
	> button[data-action="collapse"]:before { 
		content: '-'; 
	}
}
.dd-placeholder,
.dd-empty { 
	margin: 5px 0; 
	padding: 0; 
	min-height: 30px; 
	background: #f2fbff; 
	border: 1px dashed #b6bcbf; 
	@include box-sizing(border-box); 
}
.dd-empty { 
	border: 1px dashed #bbb; 
	min-height: 100px; 
	background-color: #e5e5e5;
	background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), 
					  linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
	background-size: 60px 60px;
	background-position: 0 0, 30px 30px;
}
.dd-dragel { 
	position: absolute; 
	pointer-events: none; 
	z-index: 9999; 

	> .dd-item .dd-handle {
		margin-top: 0; 
	}
	.dd-handle {
		box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
	}
}
.nestable-lists { 
	display: block; 
	clear: both; 
	padding: 30px 0; 
	width: 100%; 

	.dd-placeholder {
		background: #E9FDFB;
		border-color: #2980b9;
	}
}
.dd-hover > .dd-handle { 
	background: #2ea8e5 !important; 
}
@media only screen and (min-width: 700px) {
	.dd + .dd { 
		margin-left: 2%; 
	}
}

// #nestable2 .dd-handle {
// 	color: #fff;
// 	border: 1px solid #999;
// 	background: #bbb;
// 	background:		 linear-gradient(top, #bbb 0%, #999 100%);
// }
// #nestable2 .dd-handle:hover { 
// 	background: #bbb; 
// }
// #nestable2 .dd-item > button:before { 
// 	color: #fff; 
// }