// Core variables and mixins
@import "imports/variables";
@import "imports/mixins";

// Core layouts
@import "imports/layouts";

// Navigation, header, footer
@import "imports/header";
@import "imports/sidebar";
@import "imports/footer";

// Layouts
@import "imports/fixed_layouts";
@import "imports/boxed_layout";

// Core utilities
@import "imports/utilities";

// Components
@import "imports/bootstrap_elem_overrides";
@import "imports/tables";
@import "imports/forms";
@import "imports/gallery";
@import "imports/nestable";
@import "imports/login_stuff";
@import "imports/pricing";
@import "imports/invoice";
@import "imports/user_profile";
@import "imports/widgets";
@import "imports/project";
@import "imports/search";
@import "imports/timeline_grid";
@import "imports/other";
@import "imports/email";

// Demo only
@import "imports/demo";

// RTL Support (Right-to-Left)
@import "imports/rtl";

// Skin support
@import "imports/skins";

// Custom
@import "custom/pre-register";

.text-warning2{
  color: #f9ba25  !important;
}

.ul-album{
  list-style:none;
  margin-left:0px;
  padding-left:0px;
}

.li-album{
  height: 100px;
}

.img-preview{
  width: 100px;
}

.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
            transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
}

.rotateIn {
  -webkit-animation-name: rotateIn;
          animation-name: rotateIn;
}
[ng-click],
[data-ng-click],
[x-ng-click],
[ui-sref],
[data-ui-sref],
[x-ui-sref] {
    cursor: pointer;
}

.form-group.required label:after {
  content:"*";
  color:red;
}

.pac-container {
  z-index: 1051 !important;
}