$whiteNavColBg: #ffffff; //f3f5f6
$whiteSidebarLinkColor: #484848;

.theme-white {
	#header-navbar {
		background-color: $green-color;
	}
	.navbar > .container .navbar-brand {
		background-color: $green-color;
		width: 221px;
	}
	#nav-col,
	#page-wrapper {
		background-color: $whiteNavColBg;
	}
	#sidebar-nav .nav > li > a {
		color: $whiteSidebarLinkColor;
		/* border-bottom: 1px solid #e0e0e0; */
	}
	#sidebar-nav .nav > .open > .submenu > li > .submenu,
	#sidebar-nav .nav > .active > .submenu > li > .submenu,
	#sidebar-nav .nav li .submenu > li.open a,
	#nav-col-submenu .submenu > li > .submenu,
	#nav-col-submenu li .submenu > li.open > a {
	    background-color: darken($whiteNavColBg, 8%);
	}
	#sidebar-nav .nav > .open > .submenu > .open > a, 
	#sidebar-nav .nav > .active > .submenu > .open > a, 
	#sidebar-nav .nav > .active > .submenu > .active > a,
	#nav-col-submenu .submenu > .open > a, 
	#nav-col-submenu .submenu > .active > a {
		border-bottom-color: transparent;
	    box-shadow: none;
	}
	#sidebar-nav .nav > .open > .submenu > li > .submenu > li a:hover, 
	#sidebar-nav .nav > .active > .submenu > li > .submenu > li a:hover, 
	#sidebar-nav .nav > .active > .submenu > li > .submenu > li a.active,
	.nav-small #nav-col-submenu .submenu > li > .submenu > li a:hover,
	.nav-small #nav-col-submenu .submenu > li > .submenu > li a.active {
		color: $green-color;
	}
	.nav-pills > li.active > a, 
	.nav-pills > li.active > a:hover, 
	.nav-pills > li.active > a:focus,
	#sidebar-nav .nav-pills > li.active > a, 
	#sidebar-nav .nav-pills > li.active > a:hover, 
	#sidebar-nav .nav-pills > li.active > a:focus,
	.nav-pills > li.open > a, 
	.nav-pills > li.open > a:hover, 
	.nav-pills > li.open > a:focus,
	#sidebar-nav .nav-pills > li.open > a, 
	#sidebar-nav .nav-pills > li.open > a:hover, 
	#sidebar-nav .nav-pills > li.open > a:focus,
	.nav-small #nav-col #sidebar-nav .nav-pills > li.open > a,
	.nav-small #nav-col #sidebar-nav .nav-pills > li.active > a,
	.nav-small #nav-col-submenu .submenu > .open > a, 
	.nav-small #nav-col-submenu .submenu > .active > a,
	.nav-small #nav-col-submenu .submenu > li > a:hover {
		background-color: darken($whiteNavColBg, 4%);
		border-color: $green-color;
		border-bottom-color: #e0e0e0;
		color: $whiteSidebarLinkColor;
	}
	#sidebar-nav .nav-pills > li.active > a > i {
		color: $green-color-dark;
	}
	#sidebar-nav .nav > li > a:hover {
		background-color: darken($whiteNavColBg, 4%);
		border-color: $green-color;
		border-bottom-color: #e0e0e0;
		color: $whiteSidebarLinkColor;
	}
	#header-navbar .nav > li > a {
		color: #fff;
	}
	#header-navbar .nav > li > a:hover,
	#header-navbar .nav > li > a:focus,
	#header-navbar .nav .open > a, 
	#header-navbar .nav .open > a:hover, 
	#header-navbar .nav .open > a:focus {
		background-color: $green-color-dark;
	}
	#sidebar-nav .nav li .submenu,
	#nav-col-submenu .submenu {
		background-color: darken($whiteNavColBg, 4%);
	}
	#sidebar-nav .nav li .submenu > li > a,
	.nav-small #nav-col-submenu .submenu > li > a {
		color: #16191c;
	}
	#sidebar-nav .nav li.open > a.dropdown-toggle > .drop-icon,
	#sidebar-nav .nav li.active > a.dropdown-toggle > .drop-icon {
		color: #16191c;
	}
	#sidebar-nav .nav li .submenu > li > a:hover,
	#sidebar-nav .nav li .submenu > li > a.active,
	#sidebar-nav .nav li .submenu > li.active > a,
	.nav-small #nav-col-submenu .submenu > .open > a, 
	.nav-small #nav-col-submenu .submenu > .active > a,
	.nav-small #nav-col-submenu .submenu > li > a:hover,
	.nav-small #nav-col-submenu .submenu > li > a.active,
	.nav-small #nav-col-submenu .submenu > li.active > a {
		background-color: darken($whiteNavColBg, 8%);
	}
	.navbar > .container .navbar-brand {
		color: #fff;
	}
	.navbar-toggle {
		color: #fff;
	}
	.graph-box {
		background-color: $green-color !important;
	}
	#content-wrapper {
		background-color: #f9f9f9;
		border-left: 2px solid #e7ebee;
	}
	#user-left-box {
	
		.user-box,
		.user-box a {
			color: #16191c;
		}
		.user-box a:hover,
		.user-box a:focus {
			color: darken(#16191c, 20%);
		}
	}
	#sidebar-nav .nav > li.nav-header {
		border-top-color: #e7ebee;
		color: darken($whiteNavColBg, 35%);
	}
	.nav-tabs {
		background-color: #f9f9f9;

		> li.active > a, 
		> li.active > a:hover, 
		> li.active > a:focus {
			border-top-color: $green-color;
		}
	}
	h1 {
		color: $green-color;
	}
	#header-navbar .nav > li > a:hover,
	#header-navbar .nav > li > a:focus,
	#header-navbar .nav .open > a, 
	#header-navbar .nav .open > a:hover, 
	#header-navbar .nav .open > a:focus,
	.navbar-toggle:hover,
	.navbar-toggle:focus, 
	.mobile-search.active > .btn {
		background-color: $green-color-dark;
	}
	.main-box {
		border: 1px solid $main-bg-color;
	}
	a,
	.fc-state-default,
	.jvectormap-zoomin, 
	.jvectormap-zoomout,
	#user-profile .profile-details ul > li > span {
		color: $green-color;
	}
	a:hover,
	a:focus,
	.widget-users li > .details > .name > a:hover,
	.widget-todo .actions > a:hover {
		color: $green-color;
	}
	.table a.table-link:hover {
		color: $green-color-dark;
	}
	.pagination {
		> li {
			> a, 
			> span,
			> a:hover,
			> span:hover,
			> a:focus,
			> span:focus,
			> a:active,
			> span:active {
				color: $green-color;
			}
		}
		> .active {
			> a, 
			> span, 
			> a:hover, 
			> span:hover, 
			> a:focus, 
			> span:focus {
				background-color: $green-color;
				border-color: $green-color;
				color: #fff;
			}
		}
	}
	.notifications-list {
		.item-footer {
			background-color: #272d33;
		
			a:hover {
				background-color: #0f1114;
			}
		}
	}
	.btn-primary,
	.btn-default,
	.btn-info,
	.btn-success,
	.btn-warning,
	.btn-danger,
	.btn-primary:hover,
	.btn-default:hover,
	.btn-info:hover,
	.btn-success:hover,
	.btn-warning:hover,
	.btn-danger:hover {
		color: #fff;
	}
	.btn-primary {
		background-color: $green-color;
		border-color: $green-color-dark;
	}
	.btn-primary:hover,
	.btn-primary:focus,
	.btn-primary:active,
	.btn-primary.active, 
	.open .dropdown-toggle.btn-primary {
		background-color: $green-color-dark;
		border-color: #1a8849;
	}
	.btn-success {
		background-color: $primary-color;
		border-color: #2980b9;
	}
	.btn-success:hover,
	.btn-success:focus,
	.btn-success:active,
	.btn-success.active, 
	.open .dropdown-toggle.btn-success {
		background-color: #2980b9;
		border-color: #1c5c87;
	}
	h1 {
		color: $green-color;
	}
	.widget-users li > .details > .time {
		color: $green-color;
	}
	.conversation-item.item-right .conversation-body:before {
		border-color: transparent transparent transparent #e2fdee;
	}
	.conversation-item.item-right .conversation-body,
	.invoice-summary .invoice-summary-item {
		background-color: #e2fdee;
	}
	blockquote, 
	blockquote.pull-right {
		border-color: $green-color;
	}
	a.list-group-item.active, 
	a.list-group-item.active:hover, 
	a.list-group-item.active:focus {
		background-color: $green-color;
		border-color: $green-color;
	}
	.nav .caret {
		border-bottom-color: $green-color;
		border-top-color: $green-color;
	}
	.panel-default > .panel-heading,
	.notifications-list .item-footer {
		background-color: $green-color;
	}
	.notifications-list .item-footer a:hover {
		background-color: $green-color-dark;
	}
	#invoice-companies .invoice-dates .invoice-number > span,
	.notifications-list .item a .time {
		color: $green-color;
	}
	.table thead > tr > th > a:hover span {
		color: $green-color;
		border-color: $green-color;
	}
	#email-nav-items {
		li {
			&.active {
				border-left-color: $green-color;
				border-right-color: $green-color;
			
				a {
					color: $green-color;
				}
				.label-primary {
					background-color: $green-color;
				}
			}
			a:hover {
				color: $green-color;
			}
		}
	}
	.select2-container--default {
		.select2-selection--multiple {
			.select2-selection__choice {
				background-color: $green-color !important;
			}
		}
		.select2-results__option--highlighted[aria-selected] {
			background-color: $green-color !important;
		}
	}
	.cd-timeline-img {
		background-color: $green-color;
		box-shadow: 0 0 0 6px #bce9cf;
	}
	#cd-timeline::before {
		background-color: #bce9cf;
	}
	.dataTable thead th:hover {
		color: $green-color;
	}
	.pace .pace-progress {
		background-color: #fff;
	}
	#config-tool.closed #config-tool-cog:hover {
		background-color: $green-color;
	}
}
.rtl.theme-white #content-wrapper {
	border-right: 2px solid #e7ebee;
	border-left: 0;
}

@media only screen and (min-width: 1170px) {
	.theme-white {
		.cd-timeline-content .cd-date {
			color: $green-color;
		}
	}
}
@media (max-width: $break-sm-max) {
	.theme-white {
		#logo.navbar-brand > img.normal-logo.logo-white {
			display: block;
		}
		#logo.navbar-brand > img.normal-logo.logo-black {
			display: none;
		}
		.navbar > .container .navbar-brand {
			background-color: $green-color;
		}
	}
}