#footer-bar {
	background: #fff;
	border-top: 1px solid $main-bg-color;
    bottom: 0;
    font-size: 0.8em;
    height: 37px;
    line-height: 36px;
    margin-left: -15px;
    margin-right: -15px;
    position: absolute;
    width: 100%;

    @media (max-width: $break-xs-max) {
		margin-left: -8px;
		margin-right: -8px;
	}
	@media (max-width: $break-xxs-max) {
		margin-left: -5px;
		margin-right: -5px;
	}
}
#footer-copyright {
	text-align: center;
	margin: 0;
}