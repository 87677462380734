.feed {
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	li {
		border-bottom: 2px solid #ebebeb;
		padding: 15px 0;
		font-size: 0.875em;
		position: relative;

		&:last-child {
			border-bottom: 0;
		}

		.img {
			float: left;
			margin-right: 10px;

			img {
				border-radius: 50%;
				width: 50px;
				height: 50px;
			}
		}
		.title {
			padding-right: 55px;
			margin-left: 60px;

			a {
				font-weight: 600;

				&:hover,
				&:focus {

				}
			}
		}
		.post-time {
			color: lighten($text-color, 40%);
			padding-top: 4px;
			margin-left: 60px;
		}
		.time-ago {
			position: absolute;
			top: 16px;
			right: 0;
			color: lighten($text-color, 40%);
			font-size: 0.875em;

			> i {

			}
		}
		.photos {
			margin-left: 60px;
			margin-top: 10px;

			.item {
				float: left;
				margin-right: 2px;

				img {
					width: 50px;
					height: 50px;
				}
			}
		}
	}
}