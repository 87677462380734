@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Monda:400,700&subset=latin-ext");
.a4-letter {
  background-color: #ffffff;
  width: 210mm;
  border: 2px solid #cccccc;
  padding: 40px;
}

.a4-letter h1.title-red {
  font-size: 90px;
  text-align: center;
  font-weight: bold;
  color: red;
}

.a4-letter h2.subtitle-red {
  font-size: 40px;
  text-align: center;
  font-weight: bold;
  color: red;
}

.a4-letter .title1 {
  color: red !important;
  font-family: "Monda", sans-serif;
  /*font-family: LucidaGrande, Helvetica, Arial, Courier;*/
  font-size: 72pt;
}

.a4-letter .title2 {
  color: red !important;
  font-family: "Monda", sans-serif;
  /*font-family: LucidaGrande, Helvetica, Arial, Courier;*/
  font-size: 24pt;
  line-height: 0% !important;
}

.a4-letter strong {
  /*font-family: LucidaGrande, Helvetica, Arial, Courier !important;*/
  font-family: "Monda", sans-serif;
  font-size: 10pt !important;
}

.a4-letter h3 {
  color: #3f6b8f !important;
  /*font-family: LucidaGrande, Helvetica, Arial, Courier;*/
  font-family: "Monda", sans-serif;
  font-size: 12pt;
  margin-top: 13px;
  font-weight: bold;
  padding: 0;
  border: 0;
}

.a4-letter ul {
  list-style: none;
}

.a4-letter .title3 {
  color: red !important;
  font-family: "Monda", sans-serif;
  font-size: 18pt;
  line-height: 120% !important;
}

.a4-letter img.logo-app {
  margin-right: 10px;
  padding-right: 10px;
  width: 100px;
}

.a4-letter img.logos-header {
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 20px;
  width: 160mm;
}

.general-information-profile .profile-header .profile-img {
  float: left;
  margin-right: 10px;
}

.general-information-profile .profile-header .profile-img img {
  width: 50px;
  height: 50px;
}

.general-information-profile .profile-header .profile-data {
  float: left;
}

.general-information-profile .profile-header h2,
.general-information-profile .profile-header h3,
.general-information-profile .profile-header h4 {
  margin: 0;
  padding: 0;
}

.general-information-profile .profile-header h4 {
  padding: 5px 0;
}

/* Sidebar Nav */
#sidebar-nav .nav li .submenu > li > a {
  padding-left: 30px !important;
}

#sidebar-nav .nav > .open .submenu > li > .submenu a,
#sidebar-nav .nav > .active .submenu > li > .submenu a {
  padding-left: 35px !important;
}

.ui-select-bootstrap > .ui-select-match > .btn {
  overflow-x: hidden;
}

/* Theme Corrections over bootstrap conflicts (Vinícius Carvalho) */
.btn-xs {
  padding: 1px 5px !important;
}

.select2 {
  z-index: auto !important;
}

/* The Dream Team */
.team-tooltip {
  min-width: 300px !important;
  width: auto !important;
}

.th-img {
  display: table;
  background: #fff;
  border-top: 0px;
  padding: 0;
  margin: 0 auto;
}

.td-img-first-column {
  float: left;
  background: #fff;
  border-top: 0px;
  padding: 0;
}

.td-span-first-column {
  display: table;
  float: left;
  margin-top: 4px;
}

.loss-div-side-table {
  margin-top: 30px;
  border-left: 2px solid #d8d8d8;
  margin-left: 10px;
  margin-right: -25px;
}

.label-small-side-table {
  font-size: 20px;
  color: #f51712;
}

.label-large-side-table {
  font-size: 26px;
  font-weight: bold;
  color: #f51712;
}

.draggable {
  cursor: pointer;
  user-select: none;
}

[type=radio] + img {
  cursor: pointer;
  padding: 10px;
}

/* CHECKED STYLES */
[type=radio]:checked + img, img.checked {
  outline: 4px solid #3f6b8f;
}

/* Table Button Text */
@media screen and (max-width: 1279px) {
  table button span {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  table button span {
    display: inline-block;
  }
}

/* Profile picture */
.profile-picture-img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border: 1px solid #fff;
}

.profile-picture-img.x2 {
  width: 100px;
  height: 100px;
}

/* Rotate icon */
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

/* Margins */
.pull-right a {
  margin-right: 10px;
}

.pull-right a :last-child {
  margin-right: 0px;
}

.bar-chart {
  padding-bottom: 120px;
}

.bar-chart svg {
  overflow: overlay !important;
}

/* Virtual Fair - Registration Data - Representatives */
img.virtual-fair-registration-data-representative-modal {
  border-radius: 50%;
  max-width: 60px;
  max-height: 60px;
  border: 2px solid #efefef;
}

.bg-pf-settings-city {
  background: #f9f9f9;
  margin: 0 !important;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #e1e1e1;
  margin-bottom: 25px !important;
}

.btn-add-pf {
  margin-top: 10px;
}

.bg-orange {
  background-color: #fceee3;
}

.cke_dialog_ui_hbox_first {
  width: 0% !important;
}
