/* SEARCH */
#search-results {
	padding: 0;
	margin: 23px 0 0;
	list-style: none;

	li {
		margin-bottom: 25px;
	
		h3.title {
			border: 0 none;
			font-weight: 600;
			margin: 0 0 3px;
			padding: 0;
			font-size: 1.3em;
		}
		.meta-info {
			margin-left: 83px;

			@media (max-width: $break-xs-max) {
				margin-left: 0;
			}
		}
		.search-img {
			margin-right: 8px;
			margin-top: 4px;
			float: left;
		}
		.link-title {
			color: #006611;
			font-size: 0.875em;
			overflow: hidden;
		}
		.desc {
			font-size: 0.875em;
			margin-top: 3px;
			max-width: 750px;
		}
	}
}
#search-form {
	.input-group-btn {
		button {
			height: 46px;
			font-size: 16px;
		
			> i {
				margin-right: 3px;
			}
		}
	}

	.input-group .form-control {
		font-size: 16px;
	}
}