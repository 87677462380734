.fab-wrapper {
  position: fixed;
  right: 20px;
  bottom: 20px;
  text-align: right;
  z-index: 3;

  &.open {
    .fab-actions .fab-action {
      transform: translateY(0);
      opacity: 1;
    }

    .fab-btn {
      background-color: #1F3155;
      transform: rotate(135deg);
    } 
  }

  .fab-btn,
  .fab-action i {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  }

  .fab-btn {
    color: #fafafa !important;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    float: right;
    display: block;
    background-color: $primary-color;
    text-align: center;
    text-decoration: none !important;
    position: relative;
    text-align: center;
    transition: all 0.5s;
    transform-origin: center;

    &:before {
      content: '+';
      font-family: 'Times New Roman';
      line-height: 56px;
      font-size: 2em;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .fab-actions {
    list-style-type: none;
    padding: 0;
    margin-bottom: 6px;
    text-align: right;
    display: flex;
    flex-direction: column;

    .fab-action {
      margin-top: 15px;
      transition: all 0.3s;
      transform: translateY(70%);
      opacity: 0;
      margin: 6px 0;

      a {
        color: $text-color !important;
        cursor: pointer;
        display: inline-block;
        padding: 0 8px;
        text-decoration: none !important;
        line-height: 40px;
        font-weight: 600;
      }

      i {
        color: $primary-color;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #fafafa;
        margin-left: 10px;
        float: right;
      }
    }
  }
}
