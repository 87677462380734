/* BACKGROUNDS */
.emerald-bg {
	background-color: $primary-color !important;
}
.red-bg {
	background-color: $red-color !important;
}
.yellow-bg {
	background-color: $yellow-color !important;
}
.green-bg {
	background-color: $green-color !important;
}
.purple-bg {
	background-color: $purple-color !important;
}
.gray-bg {
	background-color: $gray-color !important;
}
.white-bg {
	background-color: #ffffff !important;
}

/* COLORS */
.emerald {
	color: $primary-color !important;
}
.red {
	color: $red-color !important;
}
.yellow {
	color: $yellow-color !important;
}
.green {
	color: $green-color !important;
}
.purple {
	color: $purple-color !important;
}
.gray {
	color: $gray-color !important;
}

/* STATUS AND TEXT COLORS */
.status-green {
	color: #005826;
}
.status-red {
	color: #9e0b0f;
}

.txt-white {
	color: #fff !important;
}
.txt-white-hover:hover {
	color: #fff !important;
}

/* MARGINS */
.mrg-b-xs {
	margin-bottom: 3px;
}
.mrg-b-sm {
	margin-bottom: 6px;
}
.mrg-b-md {
	margin-bottom: 10px;
}
.mrg-b-lg {
	margin-bottom: 20px;
}
.mrg-t-xs {
	margin-top: 3px;
}
.mrg-t-sm {
	margin-top: 6px;
}
.mrg-t-md {
	margin-top: 10px;
}
.mrg-t-lg {
	margin-top: 20px;
}
.mrg-r-xs {
	margin-right: 3px;
}
.mrg-r-sm {
	margin-right: 6px;
}
.mrg-r-md {
	margin-right: 10px;
}
.mrg-r-lg {
	margin-right: 20px;
}
.mrg-l-xs {
	margin-left: 3px;
}
.mrg-l-sm {
	margin-left: 6px;
}
.mrg-l-md {
	margin-left: 10px;
}
.mrg-l-lg {
	margin-left: 20px;
}